<template>
  <v-card class="unvergessen-shadow">
    <div>
      <h3 style="text-align: center" class="mt-6 grey3 py-2">Gedenkseiten Herkunft nach Zeit - {{current}}</h3>
      <div style="padding: 10px">
        <apex-chart
          type="line"
          height="350"
          ref="realtimeChart"
          :options="chartOptions"
          :series="series"
        ></apex-chart>

        <h3 style="text-align: center" class="mt-6">Kumuliert</h3>

        <apex-chart
          type="line"
          height="350"
          ref="realtimeChartAccumulated"
          :options="chartOptions"
          :series="cumulated_series"
        ></apex-chart>
        <div v-if="timePeriod && timePeriod.changed === true" v-intersect="updatePeriod()"></div>
      </div>
    </div>
  </v-card>
</template>

<script>
import ApexChart from 'vue-apexcharts'
import axios from 'axios'

export default {
  props: ['timePeriod'],
  data () {
    return {
      dataObject: {},
      chartOptions: {},
      series: [
        {
          name: 'Seiten pro Tag',
          type: 'column',
          data: []
        },
        {
          name: 'Plan',
          type: 'line',
          data: []
        }
      ],
      cumulated_series: [
        {
          name: 'Kumulierte Seiten pro Tag',
          type: 'column',
          data: []
        },
        {
          name: 'Kumulierter  Plan',
          type: 'line',
          data: []
        }
      ]
    }
  },
  methods: {
    updatePeriod () {
      axios.post('/statistics/memorialPage/dailyDetails', { perdiod: this.timePeriod.period, type: this.current }).then((res) => {
        this.dataObject = res.data
        this.series[0].data = res.data.pages

        this.$refs.realtimeChart.updateSeries([{
          data: res.data.pages
        },
        {
          data: res.data.goal
        }], false, true)

        var accumulatedData = []
        for (var i = 0; i < this.dataObject.pages.length; i++) {
          if (i === 0) {
            accumulatedData[i] = this.dataObject.pages[i]
          } else {
            accumulatedData[i] = this.dataObject.pages[i] + accumulatedData[i - 1]
          }
        }
        var accumulatedDataGoal = []
        for (i = 0; i < this.dataObject.goal.length; i++) {
          if (i === 0) {
            accumulatedDataGoal[i] = this.dataObject.goal[i]
          } else {
            accumulatedDataGoal[i] = this.dataObject.goal[i] + accumulatedDataGoal[i - 1]
          }
        }
        this.$refs.realtimeChartAccumulated.updateSeries([{
          data: accumulatedData
        },
        {
          data: accumulatedDataGoal
        }], false, true)
        this.$emit('update', false)
      }).catch(() => {})
    }
  },
  created () {
    axios.post('/statistics/memorialPage/dailyDetails', { perdiod: this.timePeriod.period, type: this.current }).then((res) => {
      this.dataObject = res.data
      this.series[0].data = res.data.pages

      this.$refs.realtimeChart.updateSeries([{
        data: res.data.pages
      },
      {
        data: res.data.goal
      }], false, true)

      var accumulatedData = []
      for (var i = 0; i < this.dataObject.pages.length; i++) {
        if (i === 0) {
          accumulatedData[i] = this.dataObject.pages[i]
        } else {
          accumulatedData[i] = this.dataObject.pages[i] + accumulatedData[i - 1]
        }
      }
      var accumulatedDataGoal = []
      for (i = 0; i < this.dataObject.goal.length; i++) {
        if (i === 0) {
          accumulatedDataGoal[i] = this.dataObject.goal[i]
        } else {
          accumulatedDataGoal[i] = this.dataObject.goal[i] + accumulatedDataGoal[i - 1]
        }
      }
      this.$refs.realtimeChartAccumulated.updateSeries([{
        data: accumulatedData
      },
      {
        data: accumulatedDataGoal
      }], false, true)
    }).catch(() => {})
  },
  computed: {
    current () {
      return this.$route.params.id
    }
  },
  components: {
    apexChart: ApexChart
  }
}
</script>

<style scoped>
</style>
