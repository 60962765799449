<template>
  <div>
    <router-link :to="{ name: 'StatisticsMemorialPageOverview' }">Zurück</router-link>
    <div style="padding: 20px">
      <unvergessen-detail-card :timePeriod="customPeriod" v-on:update="update()"></unvergessen-detail-card>
    </div>
  </div>
</template>

<script>
import DetailCard from '@/components/statistics/pages/DetailedCard.vue'

export default {
  props: ['customPeriod'],
  methods: {
    update () {
      this.$emit('update', false)
    }
  },
  computed: {
    heading () {
      return this.$route.query.m ? 'Umsatz Plan-Ist-Vergleich auf täglicher Basis im ' + this.month + ' (netto)' : 'Umsatz Plan-Ist-Vergleich auf täglicher Basis (netto)'
    },
    month () {
      return new Date(new Date().getFullYear(), this.$route.query.m, 1).toLocaleDateString('de-DE', { year: 'numeric', month: 'long' })
    }
  },
  components: {
    unvergessenDetailCard: DetailCard
  }
}
</script>
